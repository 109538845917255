<template lang="pug">
  div.row
    div.col-12
      div.form-check.form-switch.form-control-lg
        input.form-check-input#flexSwitchCheckDefault(type="checkbox", v-model="show_all", @change="get")
        label.form-check-label(for="flexSwitchCheckDefault") Show All
    div.col-12
      div.table-responsive
        table.table.table-striped.atarget-table.table-sm
          thead
            tr
              th.id ID
              th.status Status
              th.url URL
              th.id <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user" data-v-12cac6b8=""><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
              th.id Notify
              th.at At
          tbody
            tr(v-for="at,index in ats", :key="at.id")
              td.id {{ at.id }}
              td
                select.form-select(v-model="at.status", @change="update(index)")
                  option(v-for="s in status", :key="s.id", :value="s.id") {{ s.label }}
              td.url
                a(:href="at.url", target="_blank", rel="noopener") {{ at.url }}
              td.id {{ at.user_id }}
              td.id <svg v-show="at.notify" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
              td {{ at.at | moment }}
</template>

<script>
import moment from 'moment';

export default {
  name:"AttemptUsers",
  data (){
    return {
      ats:[
      ],
      status:[
        { id:0, label:"" },
        { id:1, label:"Attempt" },
        { id:2, label:"Registered" },
        { id:3, label:"Fail" },
        { id:4, label:"Survey" },
        { id:5, label:"Working" },
        { id:6, label:"Complete" },
        { id:7, label:"Notified" },
        { id:8, label:"UserComplete" }
      ],
      show_all:false
    }
  },
  mounted (){
    this.get();
  },
  methods: {
    get (){
      let url = "/api/attempttargets";
      if (this.show_all)
        url += "?all"
      this.axios(url)
      .then((res)=>{
        this.ats = res.data;
      });
    },
    update (index){
      this.axios.post("/api/attempttargets", { id:this.ats[index].id, status:this.ats[index].status })
      .catch((e)=>{
        console.log(e);
        this.$swal("","","error");
      })
    }
  },
  filters: {
    moment (timestamp) {
      if (timestamp)
        return moment(new Date(timestamp*1000)).format('YYYY/MM/DD HH:mm');
      else
        return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.atarget-table {
  &.table {
    table-layout: fixed;
  }
  th, td {
    vertical-align: middle;
  }
  .id {
    width: 50px;
    text-align: center;
  }
  .status {
    width: 200px;
  }
  .url {
    width: 480px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .at {
    width: 150px;
    text-align: center;
  }
}
</style>